<template>
    <div class="rule_page">
        <div class="top_part">
            <div class="title_part">
                <div class="arrow_left" @click="goHome"><img src="../assets/imgs/arrow_left.png" alt=""></div>
                <div class="rules_text">{{ $t('m.offerbuyRecords') }}</div>
            </div>
        </div>
        <van-loading v-if="!isFlag"></van-loading>
        <div :class="['content_part', lang === 2 ? 'rtl text_r' : 'text_l']" v-else-if="tList.length !== 0">
            <van-loading v-if="!isFlag"></van-loading>
            <div v-else >
                <van-pull-refresh v-model="refreshing" 
                    :loading-text="$t('m.loading')"
                    :pulling-text="$t('m.loading')"
                    @refresh="onRefresh" >
                    <!-- 释放提示 -->
                    <template #loosing>
                        <van-loading></van-loading>
                    </template>
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="$t('m.nomore')"
                        :loading-text="$t('m.loading')"
                        @load="onLoad"
                    >
                         <div :class="['records_item', lang === 2 ? 'rtl' : '']" v-for="(item, index) in tList" :key="index">
                            <div class="left_part">                                
                                <div class="gift_name" v-if="Number(item.day)<=1">{{ $t('m.giftandday', {m: item.name, n: item.day}) }}</div>
                                <div class="gift_name" v-else>{{ $t('m.giftandday1', {m: item.name, n: item.day}) }}</div>
                                <!-- <div class="gift_name" v-if="lang !== 2">{{ item.name }} {{ $t('m.offerText') }} {{ item.day }} {{ item.day <=1 ? $t('m.day') : $t('m.days') }}</div>
                                <div class="buy_date">{{  item.time }}</div> -->
                            </div>
                            <div class="right_part">
                                <div :class="['status', statusStyles[item.is_status], ifRtl]" v-html="statusTypes[item.is_status]"></div>
                            </div>
                         </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
        <nodata class="nodata_box" v-else></nodata>
    </div>
</template>

<script>
import { offerPaymenthistory } from '../assets/js/request'
import jumpFn from '../mixins/jumpFn'
import utilsFn from '../assets/js/commonData';
import {timeConvert} from "../assets/js/utils";
export default ({
    name: 'giftpackRules',
    mixins: [jumpFn],
    components: {
        fillinCheckcode: () => import('../components/pop/fillincheckCode.vue'),
    },
    data() {
        return {
            ifShowcheckCodespop: false,
            page: 1,
            isFlag: false,
            loading: false,
            finished: false,
            refreshing: false,
            tList: [],
            // offerId: this.$router.query.offer_id,
            //0-正在进行 1-已完成 2-已过期
            statusTypes: {
                0: this.$t('m.going'),
                1: this.$t('m.compltedandsent'),
                2: this.$t('m.expired'),
                3: this.$t('m.canceled')
            },
            statusStyles: {
                0:'green',
                1:'gray',
                2:'gray'
            },
            ifRtl: '',
            lang: this.globalData.lang !== "" ? Number(this.globalData.lang) : Number(sessionStorage.getItem('lang')),
        }
    },
    created(){
        console.log('-------------------------- 礼包历史购买记录 ------------------------')
        console.log(this.globalData, '............全局...........')
        this.ifRtl = utilsFn.getRtl(this, this.lang, 2)
        this.getgiftpackBuyrecords()
    },
    methods: {    
        onLoad() {
            setTimeout(() => {
                if (this.refreshing) {
                    this.tList = [];
                    this.refreshing = false;
                }
                this.getgiftpackBuyrecords()
            }, 1000);
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.isFlag = false

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.page = 1
            this.onLoad();
        },
        //checkout礼包历史购买情况    
        async getgiftpackBuyrecords(){
            let params = {
                uid: this.globalData.uid === "" || this.globalData.uid === undefined ? sessionStorage.getItem('uid') : this.globalData.uid,
                token: this.globalData.userINFO.token === "" || this.globalData.userINFO.token === undefined  ? sessionStorage.getItem('token') : this.globalData.userINFO.token, 
                // offer_id: "600 USD_1", //成就礼包标识码
                page: this.page,
                lang: this.lang,
            }

            sessionStorage.setItem('uid', params.uid)
            sessionStorage.setItem('token', params.token)            
            console.log(JSON.parse(JSON.stringify(params)), '..........参数')

            await offerPaymenthistory(params).then(res => {
                console.log(res, '..................res')
                if(JSON.stringify(res.data) !== '{}' && res.code === 0) {
                    let {list, page} = res.data
                    this.loading = false
                    list.forEach(item => {
                        item.time = timeConvert(item.c_time)
                    })
                    //console.log(res, '.............checkout礼包历史购买情况')
                    this.tList.push(...list)     
                    this.isFlag = true
                    this.finished = page === "" ? true : false
                    if( page !== "" ) {
                        this.page++
                    }
                } else if(res.code === 10027 ) {
                    this.isFlag = true
                    this.tList = []
                } else {
                    this.isFlag = true
                }
            })
        }
    }
})
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';
.top_part {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #fff;
    .title_part {
        @include flex-cen;
        box-sizing: border-box;
        height: 44px;
        padding: 10px 0;
        position: relative;

        .arrow_left {
            position: absolute;
            left: 16px;

            img {
                width: 24px;
                height: 24px;
            }
        }

        .rules_text {
            flex: 4;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 19px;
        }
    }
}

.content_part {
    position: relative;
    z-index: 0;
    margin-top: 44px;
    box-sizing: border-box;
    // text-align: right;
    padding: 12px 16px 8px 16px; 
    height: 690px;
    overflow-y: hidden;

    .van-loading {
        text-align: center;
    }

    // width: 100%;          
    // height: 100%;
    // padding-bottom: 40%;
    // box-sizing: border-box;
    // overflow: hidden;
    // overflow-y: scroll;
    // position: absolute;
    // top: 62px;
    // z-index: 0;
    // .th {
    //     padding: 19px 20px 17px 16px;
    //     @include flex-between;
    //     .td:first-child {
    //         flex: 0.5;
    //         width: 5vw;
    //     }
    //     .td {
    //         flex: 1;
    //         font-size: 14px;
    //         font-weight: bold;
    //         color: #FFFAD2;
    //         @include flex-cen;
    //     }
    //     .gift_img_box {
    //         width: 53px;
    //         height: 53px;
    //         background: rgba(0,0,0,0.29);
    //         border: 1px solid #B7905C;
    //         border-radius: 5px;
    //         @include flex-cen;
    //         // img {
    //         //     width: 45px;
    //         //     height: 45px;
    //         // }
    //         .other_img {
    //             width: 45px !important;
    //             height: 45px !important;
    //         }
    //         .room_themem {
    //             width: 24px !important;
    //             height: 42px !important;
    //         }
    //     }
    // }
    // .line {
    //     width: 356px;
    //     height: 1px;
    //     background-color: #FFFFFF;
    //     opacity: 0.3;
    //     margin: auto;
    // }
    .green {
        color: #73D13D;
        width: 20vw;
    }
    .gray {
        color: #B3B3B3;
    }

    .van-pull-refresh {
        .van-pull-refresh__track {
            .van-list {             
                height: 670px;
                overflow-y: auto;
                .records_item {
                    box-sizing: border-box;
                    width: 100%;
                    height: 74px;
                    opacity: 1;
                    @include flex-between;
                    .left_part {
                        @include flex-column-top;
                        flex: 3;
                        .gift_name {
                            font-size: 15px;
                            font-weight: 600;
                            color: #333333;
                            line-height: 18px;
                        }
                        .buy_date {
                            font-size: 13px;
                            font-weight: 400;
                            color: #B3B3B3;
                            line-height: 15px;
                            margin-top: 2px;
                        }
                    }
                    .right_part {                        
                        flex: 3;
                        .status {
                            width: 90%;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
}
.nodata_box {
    margin-top: 40vh;
}
</style>