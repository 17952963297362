

export default({
    getRtl(_this, lang, type){
        // console.log(_this.$i18n.locale, lang, type, '.....当前语言')
        
        let obj = {
            1: {
                langType: 'zh-EN'
            },
            2: {
                langType: 'zh-ER'
            },
            3: {
                langType: 'zh-TR'
            }
        }
        let ifRtl='';
        let _lang = lang
        _this.$i18n.locale = obj[_lang].langType

        if(type === 1)
         ifRtl = _this.$i18n.locale === 'zh-ER' ? 'rtl text_r' : 'text_l'
        else
         ifRtl = _this.$i18n.locale === 'zh-ER' ? 'rtl text_l' : 'text_r'

        return ifRtl
    },
})

/**
 * 函数防抖
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
export const $debounce = function(fn, t) {
    let delay = t || 500;
    let timer;
    // console.log(fn)
    // console.log(typeof fn)
    return function() {
        let args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, args);
        }, delay);
    }
}